.icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url(sprite.svg);
}
.icon-deer {
  width: 26px;
  height: 32px;
  background-position: 0 0;
}
.icon-acorn {
  width: 33px;
  height: 32px;
  background-position: -48px 0;
}
.icon-camera {
  width: 32px;
  height: 32px;
  background-position: 0 -48px;
}

